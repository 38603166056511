import { categories } from "../data/searchpage/data";

//  function to format the output data
export function toProperCase(value) {
  if (
    [
      "NaN",
      "Nan",
      "NAN",
      "Null",
      "--",
      "- -",
      " -  - ",
      "-1",
      -1,
      "N/A",
      null,
      "[]",
      "{}",
    ].includes(value)
  ) {
    return "N/A";
  }
  const urlPattern = /^(https?:\/\/|www\.)/i;

  if (typeof value === "string" && urlPattern.test(value.trim())) {
    return value.toLowerCase();
  }
  if (
    value === "UNC MEMBER" ||
    value === "FAMILY MEMBERS OF UNC MEMBERS" ||
    value === "SAME HOUSEHOLD AS UNC MEMBERS"
  ) {
    return value
      .split(/(\s|-|\/)/)
      .map((word) =>
        word === "UNC"
          ? "UNC"
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
  }

  if (typeof value === "string" && !urlPattern.test(value)) {
    if (value.endsWith(".0")) {
      return value.slice(0, -2);
    }
    return value
      .split(/(\s|-|\/|\()/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
  }

  return value;
}

//  function to get the field name from the category
export function getNameFromValue(value) {
  for (let category in categories) {
    for (let field of categories[category]) {
      if (field.value === value) {
        return field.name;
      }
    }
  }
  return value;
}

// function to check valid password
export const isValidPassword = (password) => {
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return regex.test(password);
};
// function to check valid email address
export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};
