import React, { useState } from "react";
import "../styles/search.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { CareerInfoFlagFields } from "../../data/searchpage/data";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import {
  categories,
  conditionLabels,
  conditions,
} from "../../data/filtersData/data";
import { isValidSqlQuery } from "../../helperFunctions/functions";

const FilterModal = ({
  setSearchData,
  searchdata,
  executeSearch,
  datapage,
  setDataPage,
}) => {
  ///////////////////// Filters States////////////////
  const [filters, setFilters] = useState([
    { field: "", fieldType: "", condition: "", value: "" }, // Start with one filter
  ]);

  const choosencategories = categories
  ///////////////////////////////// Functions////////////////

  /*****    Adding a filter          *******/
  const addFilter = () => {
    setFilters((prevFilters) => [
      ...prevFilters,
      { field: "", fieldType: "", condition: "", value: "" },
    ]);
  };

  /********************* Removing the filter *************** */
  const removeFilter = (index) => {
    setFilters((prevFilters) => {
      if (prevFilters.length === 1) {
        return [{ field: "", fieldType: "", condition: "", value: "" }];
      } else {
        return prevFilters.filter((_, filterIndex) => filterIndex !== index);
      }
    });
  };
  //// function to clear the first filter only
  const clearFilter = (index) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, idx) => {
        if (idx === index) {
          return {
            ...filter,
            field: "",
            fieldType: "",
            condition: "",
            value: "",
          };
        }
        return filter;
      })
    );
  };

  /*****    Updating Filters          *******/

  const updateFilter = (index, key, value) => {
    let newFilters = [...filters];

    if (key === "field") {
      const fieldType = categories.textFields.some((f) => f.value === value)
        ? "text"
        : categories.numberFields.some((f) => f.value === value)
          ? "number"
          : "";
      newFilters[index].fieldType = fieldType;
      newFilters[index].condition = "equals";
    }

    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  /*****    Generating Sql queries to be sent to backend directly           *******/

  const createSqlQuery = () => {
    let query = " AND ";

    filters.forEach((filter, index) => {
      if (index !== 0) {
        query += ` ${filters[index - 1].operator} `;
      }
      let value = filter.value;

      if (value === "" || filter.field === "" || filter.condition === "") {
        return null;
      }
      const formattedValue = filter.fieldType === 'number' ? value : `'${value.toUpperCase()}'`;
      switch (filter.condition) {
        case "equals":
          query += `${filter.field} = ${formattedValue}`;
          break;
        case "doesntEqual":
          query += `${filter.field} != ${formattedValue}`;
          break;
        case "startsWith":
          query += `${filter.field} LIKE '${value.toUpperCase()}%'`;
          break;
        case "endswith":
          query += `${filter.field} LIKE '%${value.toUpperCase()}'`;
          break;
        case "doesntContain":
          query += `${filter.field} NOT LIKE '%${value.toUpperCase()}%'`;
          break;
        case "doesntstartwith":
          query += `${filter.field} NOT LIKE '${value.toUpperCase()}%'`;
          break;
        case "doesntendwith":
          query += `${filter.field} NOT LIKE '%${value.toUpperCase()}'`;
          break;
        case "contains":
          query += `${filter.field} LIKE '%${value.toUpperCase()}%'`;
          break;
        case "greater":
          query += `${filter.field} > ${formattedValue}`;
          break;
        case "less":
          query += `${filter.field} < ${formattedValue}`;
          break;
        case "greatEq":
          query += `${filter.field} >= ${formattedValue}`;
          break;
        case "lessEq":
          query += `${filter.field} <= ${formattedValue}`;
          break;
        default:

      }
    });
    if (query.endsWith(" AND ")) {
      query = query.slice(0, -5);
    } else if (query.endsWith(" OR ")) {
      query = query.slice(0, -4);
    } else if (query.endsWith(" WHERE ")) {
      query = query.slice(0, -7);
    } else if (query.endsWith(" undefined ")) {
      query = query.slice(0, -11);
    }

    return query;
  };

  /*****    Applying Filters to the data         *******/

  const handleApplyFilters = () => {
    const query = filters.length > 0 ? createSqlQuery() : null;
    if (
      filters.length > 0 &&
      (query === " AND " || query === "" || /undefined/.test(query))
    ) {
      toast.error("Please fill your filter values");
      return;
    }
    setSearchData((prevSearchData) => ({
      ...prevSearchData,

      FilterStatments: query || "",
    }));
    toast.success("Filters Applied , Please press Filter results button");
    setDataPage(1);
  };

  return (
    <>
      {/* ############################################################################# */}
      {filters.map((filter, index) => (
        <div key={index} className="form-group mt-3 w-100">
          <div className="flex justify-between">
            <label
              className="block text-sm font-semibold text-start text-gray-700 mb-2"
              style={{
                color: "#6D6E85",
                fontSize: "14px",
                letterSpacing: "0.07em",
              }}
            >
              Filter {index + 1}
            </label>
            {filters.length > 1 ? (
              <ClearOutlinedIcon
                onClick={() => removeFilter(index)}
                style={{
                  color: "#6d6e85",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              ></ClearOutlinedIcon>
            ) : (
              <ClearOutlinedIcon
                onClick={() => clearFilter(index)}
                style={{
                  color: "#6d6e85",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              ></ClearOutlinedIcon>
            )}
          </div>

          <div className="row mb-3 d-flex flex-wrap">
          <div className="col-sm-6 w-full sm:w-1/2 flex-grow-1 relative mb-2 sm:mb-0">
            <select
                className="appearance-none w-full px-2 py-2 pr-8 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none truncate"
                style={{
                  backgroundSize: "1.5em 1.5em",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                }}
                value={filter.field}
                onChange={(e) => updateFilter(index, "field", e.target.value)}
              >
                <option value="">Choose a Field</option>
                {Object.keys(choosencategories).map((category) =>
                  choosencategories[category]
                    .filter((field) => !CareerInfoFlagFields.includes(field.value))
                    .map((field) => (
                      <option key={field.value} value={field.value}>
                        {field.name}
                      </option>
                    ))
                )}
              </select>


            </div>
            <div className="col-sm-6 w-full sm:w-1/2 flex-grow-1 relative">
            <select
                className="appearance-none w-full px-3 py-2 pr-8 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none truncate"
                style={{
                  backgroundSize: "1.5em 1.5em",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#F2F3F7",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                }}
                value={filters[index].condition}
                onChange={(e) => updateFilter(index, "condition", e.target.value)}
              >
                <option className="tracking-tighter text-sm" value="">
                  Condition
                </option>
                {filters[index].fieldType &&
                  conditions[filters[index].fieldType].map((condition) => (
                    <option key={condition} value={condition}>
                      {conditionLabels[condition]}
                    </option>
                  ))}
              </select>


              {/* <select
                className="appearance-menulist-button w-full px-2 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                style={{
                  background: "#F2F3F7",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                }}
                value={filter.condition}
                onChange={(e) =>
                  updateFilter(index, "condition", e.target.value)
                }
              >
                <option value="">Choose a Condition</option>
                <option value="equals">Equals</option>
                <option value="doesntEqual">Doesn't Equal</option>
                <option value="contains">Contains</option>
                <option value="doesntContain">Doesn't Contain</option>
                <option value="startsWith">Starts with</option>
                <option value="endswith">Ends with</option>
                <option value="doesntstartwith">Doesn't Start with</option>
                <option value="doesntendwith">Doesn't End with</option>
                <option value="greater">Greater than</option>
                <option value="less">Less than</option>
                <option value="greatEq">Greater than or equal</option>
                <option value="lessEq">Less than or equal</option>
              </select> */}
            </div>
          </div>

          <input
            value={filter.value}
            className="appearance-menulist-button w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
            style={{
              background: "#F2F3F7",
            }}
            onChange={(e) => updateFilter(index, "value", e.target.value)}
            placeholder="Enter value"
          />

          {index !== filters.length - 1 && (
            <div className="form-group mt-3">
              <label
                className="block text-sm font-semibold text-start text-gray-700 mb-2"
                style={{
                  color: "#6D6E85",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                }}
              >
                Choose Logical Operator:
              </label>
              <select
                className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236d6e85' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                  backgroundPosition: "right 0.75rem center",
                  backgroundSize: "1.5em 1.5em",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#F2F3F7",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)"
                }}
                value={filter.operator}
                onChange={(e) => updateFilter(index, "operator", e.target.value)}
              >
                <option value="">Select Operator</option>
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </select>

            </div>
          )}
        </div>
      ))}
      <div className="flex justify-content-between">
        {" "}
        <button
          onClick={addFilter}
          style={{ backgroundColor: "rgb(12 243 186 / 22%)" }}
          className={`flex items-center justify-center px-3 py-2 mt-3 rounded text-black font-bold  `}
        >
          <AddIcon style={{ color: "#00CC99" }} sx={{ fontSize: "22px" }} />

          <span style={{ color: "#00CC99" }}>Add Filter</span>
        </button>
        <button
          style={{ backgroundColor: "rgb(12 243 186 / 22%)" }}
          className={`flex items-center justify-center px-2 py-2 mt-3 rounded text-black font-bold  `}
          onClick={handleApplyFilters}
        >
          <FilterAltIcon
            style={{ color: "#00CC99" }}
            sx={{ fontSize: "22px" }}
          />

          <span style={{ color: "#00CC99" }}>Update Filter</span>
        </button>
      </div>

      {/* <button onClick={handleClearFilters} className="clear-filters-btn">
        <span className="clear-filters-txt">Clear Filters</span>
      </button> */}
    </>
  );
};

export default FilterModal;
