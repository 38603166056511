import React from "react";
import { useLocation } from "react-router-dom";

const ContentWrapper = ({ children }) => {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  const contentWrapperStyle = {
    minHeight: "calc(100vh - 50px)",
    paddingBottom: isLoginPage ? "0" : "50px",
  };

  return (
    <div className="content-wrapper" style={contentWrapperStyle}>
      {children}
    </div>
  );
};

export default ContentWrapper;
