import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
//import "../../components/styles/LoginPage.css"; // Ensure this file has the gradient and other styles
import OTPForm from "../../components/OneTimePassword";
import { login } from "../../services/loginApi/api";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import "../../components/styles/index.css";
import vantiqaLogo from "../../images/Vantiqa logos-02@2x.png";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Input, Ripple, initTWE } from "tw-elements";
import UserContext from "../../components/Context/userContext";
import { jwtDecode } from "jwt-decode";

initTWE({ Input, Ripple });

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("Password is required"),
});

const Login = () => {
  const { setUserRole } = useContext(UserContext);

  const history = useHistory();
  const [loginLoading, setLoginLoading] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [usernameForOTP, setUsernameForOTP] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const token = Cookies.get("ElectionToken");
    if (token) {
      history.push(`/`);
    }
  }, [history]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoginLoading(true);
    values.username = values.username.trim();
    try {
      const data = await login(values);
      if (data.message) {
        setUsernameForOTP(values.username);
        toast.success("Please check your email for OTP");
        setShowOTPInput(true);
      }
      if (data.token) {
        Cookies.set("ElectionToken", data.token);
        const decoded = jwtDecode(data.token);
        setUserRole(decoded.role);
        toast.success(
          `Welcome Mr. ${decoded.username.charAt(0).toUpperCase() +
          decoded.username.slice(1).toLowerCase()
          }`
        );
        history.push("/");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setSubmitting(false);
      setLoginLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <section className="h-screen relative">
      {/* Navbar - more responsive width adjustment */}
      <div className="absolute inset-x-0 top-0 z-10 pb-10 px-5 sm:px-10 md:px-20 text-white w-full">
        <div className="flex items-start justify-start mt-5 mx-0 py-3">
          <a href="#" className="h-7 sm:h-10">
            <img src={vantiqaLogo} alt="Vantiqa Logo" className="h-full" />
          </a>
        </div>
      </div>

      <div className="h-full grid grid-cols-1 md:grid-cols-5">
        {/* Background and promotional text - updated for better responsiveness */}
        <div className="flex h-full items-center justify-center lg:justify-start bg-login-screen bg-custom-pos bg-cover col-span-3">
          <div className="shrink-0 mb-10 px-5 sm:px-10 md:px-20 grow-0 basis-full lg:basis-auto xl:w-7/12">
            <h4 className="text-4xl tracking-tighter text-gray-50  text-left">
              Our team is on standby to assist you with any inquiries you might
              have.
            </h4>
          </div>
        </div>

        {/* Form Section - more responsive setup */}
        <div className="md:col-span-2 flex items-center justify-center p-4">
          <div className="w-full sm:px-6 lg:px-8">
            {!showOTPInput ? (
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                  rememberMe: false,
                }}
                validationSchema={LoginSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, getFieldProps }) => (
                  <Form className="max-w-md mx-auto w-full">
                    <div className="flex flex-col items-start justify-start">
                      <h3 className="text-slate-700 text-2xl font-semibold leading-9">
                        Hi, Welcome Back!
                      </h3>
                      <h6 className="text-gray-500 text-sm font-medium pb-4 leading-tight">
                        Log into your account
                      </h6>
                    </div>

                    <div className="flex flex-col">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="username"
                        required
                        placeholder="Username"
                        {...getFieldProps("username")}
                        error={touched.username && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        className="mt-1 mb-4 rounded-lg"
                      />
                    </div>

                    <div className="flex flex-col">
                      <TextField
                        variant="outlined"
                        type={passwordVisible ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        placeholder="Password"
                        {...getFieldProps("password")}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {passwordVisible ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        className="mb-4"
                      />
                    </div>

                    <div className="text-center lg:text-left">
                      <button
                        type="submit"
                        className="inline-block w-full rounded-lg bg-custom-green-500 px-7 pb-2 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-sm transition duration-150 ease-in-out hover:bg-custom-green-600 hover:shadow-lg focus:bg-custom-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-custom-green-600 active:shadow-lg"
                      >
                        <h5 className="text-md">
                          {loginLoading ? "Loading..." : "Login"}
                        </h5>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <div className="fade-in">
                <OTPForm username={usernameForOTP} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
