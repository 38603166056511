// Footer.js
import React, { useEffect, useState } from "react";
import "../styles/footer.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  return (
    show && (
      <footer className="footer mt-5">
        ©2024 Vantiqa | All Rights Reserved
      </footer>
    )
  );
};

export default Footer;
