import "../styles/context.css";

function RowsContext({ position, voter, OpenProfile, onClose }) {
  return (
    <div
      className="context-menu text-start"
      style={{ top: position.y, left: position.x }}
    >
      <div className="button-container">
        {voter && (
          <>
            {" "}
            <button
              className="btn btn-success full-width"
              onClick={OpenProfile}
            >
              Open Profile
            </button>
          </>
        )}

        <button
          style={{ backgroundColor: "grey", color: "white" }}
          className="btn full-width"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default RowsContext;
