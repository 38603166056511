// src/components/PrivateRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
// import jwtDecode from "jwt-decode";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("ElectionToken");

  //   const isTokenExpired = () => {
  //     try {
  //       const decodedToken = jwtDecode(token);
  //       if (decodedToken.exp < Date.now() / 1000) {
  //         Cookies.remove("ElectionToken");
  //         return true;
  //       }
  //       return false;
  //     } catch (error) {
  //       return true;
  //     }
  //   };

  const isAuthenticated = !!token;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
