import React from "react";

const LookerReport = () => {
  return (
    <>
      <div className="container-fluid mt-3" style={{ height: "130vh" }}>
        <iframe
          width="100%"
          height="100%"
          src="https://lookerstudio.google.com/embed/reporting/f08a4e35-fd56-416c-87d0-cadcef45a4ee/page/kyhkD"
          frameBorder="10"
          style={{
            border: "3px solid #ccc",
            borderRadius: "10px",
          }}
          allowFullScreen
          title="Dashboard"
        ></iframe>
      </div>
    </>
  );
};
export default LookerReport;
