function ColumnHeader({
  name,
  title,
  label,
  index,
  moveColumn,
  hideColumn,
  showContextMenu,
}) {
  const handleDragStart = (e) => {
    e.target.style.backgroundColor = "black";

    e.dataTransfer.setData("text/plain", index);
  };

  //////////////////////////////////

  ////////////////////////////////////
  const handleDrop = (e) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("text/plain");
    moveColumn(draggedIndex, index);
  };

  const handleDragOver = (e) => {
    e.target.style.backgroundColor = "#FFFFFF";

    e.target.style.color = "#000000";

    e.preventDefault();
  };

  return (
    <th
      draggable
      style={{
        cursor: "pointer",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      className="text-start"
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onContextMenu={(e) => {
        if (window.innerWidth > 768) {
          showContextMenu(e, index);
        }
      }}
      onDoubleClick={(e) => {
        if (window.innerWidth <= 768) {
          showContextMenu(e, index);
        }
      }}
      title={title}
    >
      {label}
    </th>
  );
}
export default ColumnHeader;
