import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/familytree.css";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { toProperCase } from "../../helperFunctions/functions";

function FamilyTree({ userID, initialData }) {
  const [familyData, setFamilyData] = useState(null);
  const [expandedMembers, setExpandedMembers] = useState([]);
  const [displayedMemberIds, setDisplayedMemberIds] = useState([]);
  const [fetchedFamilyMapping, setFetchedFamilyMapping] = useState({});
  const [expandedMemberIds, setExpandedMemberIds] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState([]);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    memberId: null,
  });

  //// ******************************** Getting the data for the member clicked on///
  const fetchFamilyData = async (uid) => {
    try {
      const response = await axios.get(
        `https://election-data-app.vantiqa.com/api/userfamily/${uid}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.data.message === "Token is invalid or expired") {
        toast.warning("Please login again");
        Cookies.remove("ElectionToken");
      } else {
        toast.error("Error while fetching details:", err);
      }
    }
  };

  //////// adding families
  const handleMemberClickfamily = async (id) => {
    setLoadingMembers((prev) => [...prev, id]);

    try {
      const memberFamilyData = await fetchFamilyData(id);
      if (memberFamilyData) {
        // ## fOR THE MOTHER

        const filteredMother =
          memberFamilyData.Mother &&
          memberFamilyData.Mother !== "Null" &&
          displayedMemberIds.includes(memberFamilyData.Mother.Id)
            ? "Repeated"
            : memberFamilyData.Mother;
        // ## fOR THE Father

        const filteredFather =
          memberFamilyData.Father &&
          memberFamilyData.Father !== "Null" &&
          displayedMemberIds.includes(memberFamilyData.Father.Id)
            ? "Repeated"
            : memberFamilyData.Father;
        // ## fOR THE siblings
        // Spouse
        const filteredSpouse =
          memberFamilyData.Spouse &&
          memberFamilyData.Spouse !== "Null" &&
          displayedMemberIds.includes(memberFamilyData.Spouse.Id)
            ? "Repeated"
            : memberFamilyData.Spouse;

        const filteredSiblings =
          memberFamilyData.Siblings && memberFamilyData.Siblings !== "Null"
            ? memberFamilyData.Siblings.filter(
                (sibling) => !displayedMemberIds.includes(sibling.Id)
              ).length > 0
              ? memberFamilyData.Siblings.filter(
                  (sibling) => !displayedMemberIds.includes(sibling.Id)
                )
              : "Repeated"
            : [];
        // ## for children
        const filteredChildren =
          memberFamilyData.Children && memberFamilyData.Children !== "Null"
            ? memberFamilyData.Children.filter(
                (child) => !displayedMemberIds.includes(child.Id)
              ).length > 0
              ? memberFamilyData.Children.filter(
                  (child) => !displayedMemberIds.includes(child.Id)
                )
              : "Repeated"
            : [];

        const newIds = [];
        if (filteredMother && filteredMother !== "Repeated")
          newIds.push(filteredMother.Id);
        if (filteredFather && filteredFather !== "Repeated")
          newIds.push(filteredFather.Id);
        if (filteredSpouse && filteredSpouse !== "Repeated")
          newIds.push(filteredSpouse.Id);
        if (filteredSiblings && filteredSiblings !== "Repeated") {
          filteredSiblings.forEach((sibling) => newIds.push(sibling.Id));
        }
        if (filteredChildren && filteredChildren !== "Repeated") {
          filteredChildren.forEach((child) => newIds.push(child.Id));
        }

        const filteredFamilyData = {
          ...memberFamilyData,
          Mother: filteredMother,
          Father: filteredFather,
          Spouse: filteredSpouse,
          Siblings: filteredSiblings,
          Children: filteredChildren,
        };

        // Update the displayedMemberIds and expandedMembers state
        setDisplayedMemberIds((prev) => [...prev, ...newIds]);
        setFetchedFamilyMapping((prev) => ({ ...prev, [id]: newIds }));

        setExpandedMembers((prev) => [...prev, filteredFamilyData]);
        setExpandedMemberIds((prev) => [...prev, id]);
      }
    } catch (err) {
      toast.error("error while getting the family data");
    } finally {
      setLoadingMembers((prev) => prev.filter((memberId) => memberId !== id));
    }
  };

  ///// returning the family tree for every member clicked on
  const renderExpandedTree = (id) => {
    return expandedMembers
      .filter((member) => member.User_Id === id)
      .map((memberData) => (
        <FamilyTree
          key={memberData.User_Id}
          userID={memberData.User_Id}
          initialData={memberData}
        />
      ));
  };

  ///// to open the user profile
  const openProfile = (id) => {
    window.location.href = `/personaldetails/${id}`;
  };

  const handleRemoveFromTree = (id) => {
    let idsToRemove = [id];
    if (fetchedFamilyMapping[id]) {
      idsToRemove = [...idsToRemove, ...fetchedFamilyMapping[id]];
    }

    // Remove from expandedMembers
    setExpandedMembers((prev) =>
      prev.filter((member) => !idsToRemove.includes(member.User_Id))
    );

    // Remove from displayedMemberIds except the main member ID
    setDisplayedMemberIds((prev) =>
      prev.filter((memberId) => !fetchedFamilyMapping[id]?.includes(memberId))
    );

    // Remove the main member ID from expandedMemberIds
    setExpandedMemberIds((prev) => prev.filter((memberId) => memberId !== id));

    const updatedMapping = { ...fetchedFamilyMapping };
    delete updatedMapping[id];
    setFetchedFamilyMapping(updatedMapping);
  };

  const handleRightClick = (event, memberId) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      memberId: memberId,
    });
  };
  useEffect(() => {
    const initializeFamilyData = async () => {
      if (initialData) {
        setFamilyData(initialData);

        // Extract IDs from initialData
        const idsFromInitialData = [];

        if (initialData.Mother && initialData.Mother !== "Null")
          idsFromInitialData.push(initialData.Mother.Id);
        if (initialData.Father && initialData.Father !== "Null")
          idsFromInitialData.push(initialData.Father.Id);
        if (initialData.Spouse && initialData.Spouse !== "Null")
          idsFromInitialData.push(initialData.Spouse.Id);
        if (
          initialData.Siblings &&
          initialData.Siblings !== "Null" &&
          initialData.Siblings !== "Repeated"
        ) {
          initialData.Siblings.forEach((sib) =>
            idsFromInitialData.push(sib.Id)
          );
        }
        if (
          initialData.Children &&
          initialData.Children !== "Null" &&
          initialData.Children !== "Repeated"
        ) {
          initialData.Children.forEach((child) =>
            idsFromInitialData.push(child.Id)
          );
        }
        idsFromInitialData.push(initialData.User_Id);

        setDisplayedMemberIds((prev) => [...prev, ...idsFromInitialData]);
      } else {
        const data = await fetchFamilyData(userID);
        setFamilyData(data);
        const idsFromData = [];
        if (data.Mother && data.Mother !== "Null")
          idsFromData.push(data.Mother.Id);
        if (data.Spouse && data.Spouse !== "Null")
          idsFromData.push(data.Spouse.Id);
        if (data.Father && data.Father !== "Null")
          idsFromData.push(data.Father.Id);
        if (data.Siblings && data.Siblings !== "Null") {
          data.Siblings.forEach((sib) => idsFromData.push(sib.Id));
        }
        if (data.Children && data.Children !== "Null") {
          data.Children.forEach((child) => idsFromData.push(child.Id));
        }
        idsFromData.push(data.User_Id);

        setDisplayedMemberIds((prev) => [...prev, ...idsFromData]);
      }
    };

    initializeFamilyData();
  }, [userID, initialData]);

  useEffect(() => {
    const closeContextMenu = () => {
      setContextMenu({
        ...contextMenu,
        visible: false,
      });
    };
    document.addEventListener("click", closeContextMenu);
    return () => {
      document.removeEventListener("click", closeContextMenu);
    };
  }, [contextMenu]);

  /////////// Filtering the members

  ////////////////////////////////////////////////////////////////

  if (!familyData) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress sx={{ color: "black" }} />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="treecontainer mt-5 border">
        <div className="row justify-content-center mb-4">
          {familyData.Mother &&
          familyData.Mother !== "Null" &&
          familyData.Mother !== "Repeated" ? (
            <div
              className={`col-sm-${
                familyData.Father === "Repeated" ? "10" : "5"
              } text-center`}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Mother
              </p>
              <div
                onContextMenu={(e) => {
                  if (window.innerWidth > 768) {
                    handleRightClick(e, familyData.Mother.Id);
                  }
                }}
                onDoubleClick={(e) => {
                  if (window.innerWidth <= 768) {
                    handleRightClick(e, familyData.Mother.Id);
                  }
                }}
                className=" mt-3 family-info p-3"
              >
                {" "}
                {toProperCase(familyData.Mother.Mother_Name)}
              </div>
              {renderExpandedTree(familyData.Mother.Id)}
              {loadingMembers.includes(familyData.Mother.Id) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              )}
            </div>
          ) : familyData.Mother === "Repeated" ? null : (
            <div
              className={`col-sm-${
                familyData.Father === "Repeated" ? "10" : "5"
              } text-center `}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Mother
              </p>
              <div className="family-info p-3 mt-3">
                No Information About The Mother{" "}
              </div>{" "}
            </div>
          )}

          {familyData.Father &&
          familyData.Father !== "Null" &&
          familyData.Father !== "Repeated" ? (
            <div
              className={`col-sm-${
                familyData.Mother === "Repeated" ? "10" : "5"
              } text-center`}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Father
              </p>
              <div
                onContextMenu={(e) => {
                  if (window.innerWidth > 768) {
                    handleRightClick(e, familyData.Father.Id);
                  }
                }}
                onDoubleClick={(e) => {
                  if (window.innerWidth <= 768) {
                    handleRightClick(e, familyData.Father.Id);
                  }
                }}
                className=" family-info p-3 mt-3"
              >
                {" "}
                {toProperCase(familyData.Father.Father_Name)}
              </div>
              {renderExpandedTree(familyData.Father.Id)}
              {loadingMembers.includes(familyData.Father.Id) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              )}
            </div>
          ) : familyData.Father === "Repeated" ? null : (
            <div
              className={`col-sm-${
                familyData.Mother === "Repeated" ? "10" : "5"
              } text-center `}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Father
              </p>
              <div className="family-info p-3 mt-3">
                No Information About The Father{" "}
              </div>{" "}
            </div>
          )}
        </div>
        <div className="row justify-content-center mb-4">
          <div
            className={`col-sm-${
              familyData.Spouse === "Repeated" ? "10" : "5"
            } text-center user p-4  `}
            style={{
              background: "rgb(0 204 153 / 80%)",
              border: "4px solid rgb(0 204 153 / 80%)",
            }}
          >
            {toProperCase(familyData.user_name)}
          </div>
          <div
            className={`col-sm-${
              familyData.Spouse === "Repeated" ? "0" : "5"
            } text-center`}
            style={{ background: "transparent" }}
          >
            {familyData.Spouse === "Repeated" ? null : (
              <p className="member-title text-start">Spouse</p>
            )}
            {familyData.Spouse &&
            familyData.Spouse !== "Null" &&
            familyData.Spouse !== "Repeated" ? (
              <>
                {" "}
                <div
                  className="family-info p-3 mt-3"
                  style={{
                    background: "rgb(255, 255, 255 / 80%)", // or any other styling you need
                  }}
                  onContextMenu={(e) => {
                    if (window.innerWidth > 768) {
                      handleRightClick(e, familyData.Spouse.Id);
                    }
                  }}
                  onDoubleClick={(e) => {
                    if (window.innerWidth <= 768) {
                      handleRightClick(e, familyData.Spouse.Id);
                    }
                  }}
                >
                  {toProperCase(familyData.Spouse.Spouse_Name)}
                </div>
                {renderExpandedTree(familyData.Spouse.Id)}
                {loadingMembers.includes(familyData.Spouse.Id) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <CircularProgress sx={{ color: "white" }} />
                  </div>
                )}
              </>
            ) : familyData.Spouse === "Repeated" ? null : (
              <div className="family-info p-3 mt-3 no-info">
                No Information About The Spouse
              </div>
            )}
          </div>
          {/* {familyData.Spouse &&
          familyData.Spouse !== "Null" &&
          familyData.Spouse !== "Repeated" ? (
            <div
              className={`col-sm-5 text-center`}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Spouse
              </p>
              <div
                onContextMenu={(e) => {
                  if (window.innerWidth > 768) {
                    handleRightClick(e, familyData.Spouse.Id);
                  }
                }}
                onDoubleClick={(e) => {
                  if (window.innerWidth <= 768) {
                    handleRightClick(e, familyData.Spouse.Id);
                  }
                }}
                className="family-info p-3  mt-3"
              >
                {" "}
                {toProperCase(familyData.Spouse.Spouse_Name)}
              </div>
              {renderExpandedTree(familyData.Spouse.Id)}
              {loadingMembers.includes(familyData.Spouse.Id) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              )}
            </div>
          ) : familyData.Spouse === "Repeated" ? null : (
            <div className={`col-sm-5 text-center no-info`}>
              No Information About The Spouse
            </div>
          )} */}
        </div>

        <div className="row justify-content-center mb-4">
          {familyData.Siblings !== "Null" &&
          familyData.Siblings !== "Repeated" &&
          familyData.Siblings.length > 0 ? (
            <>
              <div
                className={`col-sm-${
                  familyData.Children === "Repeated" ? "10" : "5"
                } text-center`}
                style={{
                  background: "transparent",
                }}
              >
                <p style={{ textAlign: "start" }} className=" member-title ">
                  Siblings
                </p>
                <div className="family-info border">
                  {" "}
                  {familyData.Siblings.map((sibling) => (
                    <div key={sibling.Id} className="p-2  my-1 sibling-wrapper">
                      <div
                        onContextMenu={(e) => {
                          if (window.innerWidth > 768) {
                            handleRightClick(e, sibling.Id);
                          }
                        }}
                        onDoubleClick={(e) => {
                          if (window.innerWidth <= 768) {
                            handleRightClick(e, sibling.Id);
                          }
                        }}
                        className="sibling-name-hover-effect "
                      >
                        {" "}
                        {toProperCase(sibling.Sibling_Name)}
                      </div>
                      <div className="line mt-2"></div>
                      {renderExpandedTree(sibling.Id)}
                      {loadingMembers.includes(sibling.Id) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                          }}
                        >
                          <CircularProgress sx={{ color: "white" }} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : familyData.Siblings === "Repeated" ? null : (
            <div
              className={`col-sm-${
                familyData.Children === "Repeated" ? "10" : "5"
              } text-center `}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Siblings
              </p>
              <div className="family-info p-3 mt-3">
                No Information About The Siblings{" "}
              </div>{" "}
            </div>
          )}
          {familyData.Children !== "Null" &&
          familyData.Children !== "Repeated" &&
          familyData.Children.length > 0 ? (
            <>
              <div
                className={`col-sm-${
                  familyData.Siblings === "Repeated" ? "10" : "5"
                } text-center`}
                style={{
                  background: "transparent",
                }}
              >
                <p style={{ textAlign: "start" }} className="member-title ">
                  Children
                </p>
                <div className="family-info border">
                  {familyData.Children.map((child) => (
                    <div key={child.Id} className="p-2 my-1 child-wrapper">
                      <span
                        onContextMenu={(e) => {
                          if (window.innerWidth > 768) {
                            handleRightClick(e, child.Id);
                          }
                        }}
                        onDoubleClick={(e) => {
                          if (window.innerWidth <= 768) {
                            handleRightClick(e, child.Id);
                          }
                        }}
                        className="child-name-hover-effect "
                      >
                        {" "}
                        {toProperCase(child.Child_Name)}
                      </span>
                      <div className="line mt-2"></div>

                      {renderExpandedTree(child.Id)}
                      {loadingMembers.includes(child.Id) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                          }}
                        >
                          <CircularProgress sx={{ color: "black" }} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : familyData.Children === "Repeated" ? null : (
            <div
              className={`col-sm-${
                familyData.Siblings === "Repeated" ? "10" : "5"
              } text-center `}
              style={{
                background: "transparent",
              }}
            >
              <p style={{ textAlign: "start" }} className="member-title">
                Children
              </p>
              <div className="family-info p-3  ">
                No Information About The Children
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {contextMenu.visible && (
        <div
          className="custom-context-menu"
          style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
        >
          <ul>
            <li
              onClick={() => openProfile(contextMenu.memberId)}
              style={{ color: "black" }}
            >
              Open Profile
            </li>
            {expandedMemberIds.includes(contextMenu.memberId) ? (
              <li
                onClick={() => handleRemoveFromTree(contextMenu.memberId)}
                style={{ color: "black" }}
              >
                Hide family
              </li>
            ) : (
              <li
                onClick={() => handleMemberClickfamily(contextMenu.memberId)}
                style={{ color: "black" }}
              >
                Show family
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default FamilyTree;
