// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
  background: #0184a0 0% 0% no-repeat padding-box;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  font: normal normal normal 20px/28px proxima-nova, sans-serif;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0; /* Ensure the footer spans the entire width from the left */
  box-sizing: border-box; /* Ensure padding does not affect the total width */
  z-index: 1000; /* Ensure footer stays above other content */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .footer {
    font-size: 16px; /* Adjust font size for better readability on small screens */
    padding: 8px 0; /* Adjust padding to ensure content fits well */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .footer {
    font-size: 14px; /* Further reduce font size for very small screens */
    padding: 5px 0; /* Reduce padding to minimize space usage */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,+CAA+C;EAC/C,cAAc;EACd,kBAAkB;EAClB,0BAA0B;EAC1B,6DAA6D;EAC7D,eAAe;EACf,eAAe;EACf,SAAS;EACT,WAAW;EACX,OAAO,EAAE,2DAA2D;EACpE,sBAAsB,EAAE,mDAAmD;EAC3E,aAAa,EAAE,4CAA4C;AAC7D;;AAEA,oCAAoC;AACpC;EACE;IACE,eAAe,EAAE,6DAA6D;IAC9E,cAAc,EAAE,+CAA+C;EACjE;AACF;;AAEA,+CAA+C;AAC/C;EACE;IACE,eAAe,EAAE,oDAAoD;IACrE,cAAc,EAAE,2CAA2C;EAC7D;AACF","sourcesContent":["/* Footer.css */\n.footer {\n  background: #0184a0 0% 0% no-repeat padding-box;\n  color: #ffffff;\n  text-align: center;\n  text-transform: capitalize;\n  font: normal normal normal 20px/28px proxima-nova, sans-serif;\n  padding: 10px 0;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  left: 0; /* Ensure the footer spans the entire width from the left */\n  box-sizing: border-box; /* Ensure padding does not affect the total width */\n  z-index: 1000; /* Ensure footer stays above other content */\n}\n\n/* Adjustments for smaller screens */\n@media (max-width: 768px) {\n  .footer {\n    font-size: 16px; /* Adjust font size for better readability on small screens */\n    padding: 8px 0; /* Adjust padding to ensure content fits well */\n  }\n}\n\n/* Further adjustments for very small screens */\n@media (max-width: 480px) {\n  .footer {\n    font-size: 14px; /* Further reduce font size for very small screens */\n    padding: 5px 0; /* Reduce padding to minimize space usage */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
