// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu {
  position: absolute;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000;
  transition: 0.5s;
}

.context-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.column-list {
  margin-bottom: 15px;
}

.column-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.column-label .input {
  margin-right: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button-container .btn {
  flex: 1 0 auto;
  margin: 0.5em;
}

.button-container .btn:last-child {
  margin-right: 0;
}

.button-container .btn.full-width {
  margin-right: 0;
}

.button-container .btn svg {
  margin-right: 5px;
}

@media (max-width: 480px) {
  .context-menu {
    width: 30%;
  }
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }

  .button-container .btn {
    margin: 0.5em 0;
    flex: 1 0 auto;
  }
}

.input.contextMenuLabel {
  margin-bottom: 0px !important;
  width: 7% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/context.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,+CAA+C;EAC/C,uBAAkB;EAAlB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;AACF;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB","sourcesContent":[".context-menu {\n  position: absolute;\n  background: #f2f2f2;\n  border-radius: 5px;\n  padding: 15px;\n  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);\n  width: fit-content;\n  z-index: 1000;\n  transition: 0.5s;\n}\n\n.context-title {\n  font-size: 1.2em;\n  margin-bottom: 10px;\n}\n\n.column-list {\n  margin-bottom: 15px;\n}\n\n.column-label {\n  display: flex;\n  align-items: center;\n  margin-bottom: 5px;\n}\n\n.column-label .input {\n  margin-right: 5px;\n}\n\n.button-container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.button-container .btn {\n  flex: 1 0 auto;\n  margin: 0.5em;\n}\n\n.button-container .btn:last-child {\n  margin-right: 0;\n}\n\n.button-container .btn.full-width {\n  margin-right: 0;\n}\n\n.button-container .btn svg {\n  margin-right: 5px;\n}\n\n@media (max-width: 480px) {\n  .context-menu {\n    width: 30%;\n  }\n}\n\n@media (max-width: 600px) {\n  .button-container {\n    flex-direction: column;\n  }\n\n  .button-container .btn {\n    margin: 0.5em 0;\n    flex: 1 0 auto;\n  }\n}\n\n.input.contextMenuLabel {\n  margin-bottom: 0px !important;\n  width: 7% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
