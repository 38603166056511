import React, { createContext, useState, useContext } from "react";

const MunicipalityContext = createContext();

export const useMunicipality = () => useContext(MunicipalityContext);

export const MunicipalityProvider = ({ children }) => {
  const [municipality, setMunicipality] = useState(null);

  return (
    <MunicipalityContext.Provider value={{ municipality, setMunicipality }}>
      {children}
    </MunicipalityContext.Provider>
  );
};
