import { useState } from "react";
import "../styles/context.css";
function ContextMenu({ position, hide, hideColumn, showColumn, columns }) {
  const hiddenColumns = columns.filter((column) => !column.visible);
  const [selectedColumn, setSelectedColumn] = useState("");
  const onAddButtonClick = () => {
    if (selectedColumn) {
      showColumn(selectedColumn);
    }
  };

  const onSelectChange = (e) => {
    setSelectedColumn(e.target.value);
  };

  return (
    <div
      className="context-menu text-start"
      style={{ top: position.y, left: position.x }}
    >
      {hiddenColumns.length > 0 && (
        <div>
          <span className="context-title">Hidden Columns</span>
          <ul className="column-list ">
            {hiddenColumns.map((column, index) => (
              <label className="column-label contextMenuLabel" key={index}>
                <input
                  name="radio"
                  type="radio"
                  className="input contextMenuLabel"
                  value={column.name}
                  onChange={onSelectChange}
                />
                {column.label}
              </label>
            ))}
          </ul>
        </div>
      )}
      <div className="button-container">
        <button
          className={`btn btn-primary ${
            hiddenColumns.length > 0 ? "full-width" : "d-none"
          }`}
          onClick={onAddButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill="currentColor"
              d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
            ></path>
          </svg>
          Add
        </button>
        <button
          className={`btn btn-danger ${
            hiddenColumns.length > 0 ? "" : "full-width"
          }`}
          onClick={hideColumn}
        >
          Hide{" "}
        </button>
        <button
          style={{ backgroundColor: "grey", color: "white" }}
          className={`btn  ${hiddenColumns.length > 0 ? "" : "full-width"}`}
          onClick={hide}
        >
          Close
        </button>
      </div>
    </div>
  );
}
export default ContextMenu;
